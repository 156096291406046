import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import axios from 'axios';

import { AUTH_URL } from './../config.js';

const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser'));

async function login(email, password) {
	try {
		const response = await axios.post(AUTH_URL + "/designer-panel/auth/login", {
			email, password
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		});
		const { message, ...user } = response.data;
		localStorage.setItem('currentUser', JSON.stringify(user));
		currentUserSubject.next(JSON.stringify(user));
		return;
	} catch (error) {
		console.log(error);
		if (error.response) {
			if (error.response.data.message == 'Too many failed login attempts') {
				const message = `Too many failed login attempts, please try after ${moment.unix(error.response.data.tryAfter).format('MMMM Do YYYY, h:mm:ss a')}`
				throw message;
			} else {
				throw error.response.data.message;
			}
		} else {
			throw 'Something went wrong!';
		}
	}
}

async function register(first_name, email, password,mobile) {
	try {
		const response = await axios.post(AUTH_URL + "/designer-panel/auth/register", {
			name:first_name,
			email,
			password,
			mobile
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		});
		sessionStorage.setItem('registererId', response.data.uniqueId);
		return;
	} catch (error) {
		console.log(error);
		if (error.response) {
			throw error.response.data.message;
		} else {
			throw 'Something went wrong!';
		}
	}
}

async function verifyRegisterEmailVerificationOTP(uniqueId, otp) {
	try {
		const response = await axios.post(AUTH_URL + "/designer-panel/auth/verify-register-email-verification-otp", {
			uniqueId, otp
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		});
		const {...user } = response.data;
		localStorage.setItem('currentUser', JSON.stringify(user));
		sessionStorage.removeItem('registererId');
		currentUserSubject.next(user);
		return 
	} catch (error) {
		// console.log(error);

		if (error.response) {
			throw error.response.data.message;
		} else {
			throw 'Something went wrong';
		}
	}
}

async function forgotPassword(email) {
	try {
		const response = await axios.post(AUTH_URL + '/designer-panel/auth/forgot-password', {
			email: email
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		});
		return response.data.message;
	} catch (error) {
		console.log(error);
		if (error.response) {
			if (error.response.data.message == 'Max attempts reached') {
				const message = `Max attempts limit reached, please try after ${moment.unix(error.response.data.tryAfter).format('MMMM Do YYYY, h:mm:ss a')}`
				throw message;
			} else {
				throw error.response.data.message;
			}
		} else {
			throw "Something went wrong!"
		}
	}
}


async function resetPassword(token, password, confirmPassword) {
	try {
		const response = await axios.post(AUTH_URL + '/designer-panel/auth/reset-password', {
			token: token,
			password: password,
			confirmPassword: confirmPassword
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
		return response.data.message;
	} catch (error) {
		console.log(error);
		if (error.response) {
			throw error.response.data.message;
		} else {
			throw "Something went wrong!"
		}
	}
}
function logout() {
	localStorage.removeItem('currentUser');
	// currentUserSubject.next(null);
	window.location.reload();
}

export const AuthService = {
	currentUser: currentUserSubject.asObservable(),
	get currentUserValue() { return currentUserSubject.value },
	login,
	register,
	logout,
	forgotPassword,
	resetPassword,
	verifyRegisterEmailVerificationOTP
};