import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { IconFileUpload } from "@tabler/icons-react";
import axios from "axios";
import { Fragment, useState } from "react";
import { dbCall } from "../../common/db";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const people = [
    { name: 'E-commerce' },
    { name: 'Portfolio' },
    { name: 'Business Showcase' },
    { name: 'CMS' },
    { name: 'Tanya Fox' },
    { name: 'Hellen Schmidt' },
]
export default function UploadDesign() {
    const [selected, setSelected] = useState(people[0])
    const [data, setData] = useState({})
    const [submit, setSubmit] = useState(false)

    const naviagte = useNavigate()
    const handleSubmit = async () => {
        try {
            setSubmit(true)
            if (!data?.name || !data?.description || !data?.category || !data?.topic || !data?.file) {
                toast.error("All fields are required")
                return
            }
            const filesData = new FormData(); 		// use FormData else it will give 'data too long' error

            // filesData.append("profilePic", data.file);
            filesData.append("zipFile", data.file);
            filesData.append("name", data.name);
            filesData.append("category", data.category);
            filesData.append("topic", data.topic);
            filesData.append("description", data.description);

            const fetch = await dbCall.post("/designer-panel/save-template", filesData)

            console.log(fetch, "response")
            toast.success("Design upload successfully")
            setData({})
            setSubmit(true)
            naviagte("/user/profile")
        } catch (e) {
            console.log(e)
            toast.error("Something went wrong")
        }

        // const response = await fetch.json()
    }
    return (
        <>
            <div className="h-[90vh] flex items-center justify-center flex-col">
                <h1 className="font-bold text-xl flex items-center justify-center mb-10">Upload Your Design</h1>

                <div class="flex items-center justify-center w-[100%]">
                    <div class="mx-auto w-full max-w-[550px] bg-white">
                        <div class="py-4 px-9">
                            <div class="mb-5">
                                <label for="email" class="mb-2 block text-base font-medium text-[#07074D]">
                                    <span className="text-red-500">*</span>Name of Your template:
                                </label>
                                <input type="name" name="email" id="email" placeholder="Template Name here"
                                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                    onChange={(e) => {
                                        setData({ ...data, name: e.target.value })
                                    }}
                                />
                            </div>
                            <div class="mb-5">
                                <label for="email" class="mb-2 block text-base font-medium text-[#07074D]">
                                    <span className="text-red-500">*</span>Description of Your template:
                                </label>
                                <input type="name" name="email" id="email" placeholder="Template Name here"
                                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                    onChange={(e) => {
                                        setData({ ...data, description: e.target.value })
                                    }}
                                />
                            </div>

                            <div class="mb-6 pt-4">
                                <label class="mb-5 block text-xl font-semibold text-[#07074D]">
                                    <span className="text-red-500">*</span>Upload File
                                </label>

                                <div class="mb-8">
                                    <input type="file" name="file" id="file" class="sr-only"
                                        onChange={(e) => {
                                            console.log(e.target.files[0])
                                            if (e?.target?.files?.[0]?.size > 10485760) {
                                                toast.error("please upload file under 10 mb")
                                                return
                                            }
                                            if (e?.target?.files?.[0]?.type != "application/zip") {
                                                toast.error("only zip files are allowed")
                                                return
                                            }
                                            setData({ ...data, file: e?.target?.files?.[0] })
                                        }}
                                    />
                                    <label for="file"
                                        class="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center">
                                        <div>
                                            <div className="flex items-center justify-center">
                                                <IconFileUpload className="text-gray-500 h-12 w-12 text-center" stroke={1} />
                                            </div>
                                            <span
                                                class="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] cursor-pointer">
                                                Browse File
                                            </span>
                                        </div>
                                    </label>
                                    <span>
                                        {data?.file?.name}
                                    </span>
                                </div>
                            </div>

                            <div class="mb-5">
                                <label className="mb-2 text-base font-medium">
                                    <span className="text-red-500">*</span>Choose Category
                                </label>
                                <select className="w-[100%]"
                                    onChange={(e) => {
                                        setData({ ...data, topic: e.target.value })
                                    }}
                                >
                                    <option value="" selected disabled hidden>Select template Category</option>
                                    {
                                        people?.map((person, personIdx) => (

                                            <>
                                                <option
                                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                >
                                                    {person.name}
                                                </option>
                                            </>
                                        ))}
                                </select>

                            </div>

                            <div class="mb-5 flex flex-col">

                                <label className="mb-2 text-base font-medium">
                                    <span className="text-red-500">*</span> Design Topic
                                </label>
                                <input placeholder="Enter Topic" type="text"
                                    onChange={(e) => {
                                        setData({ ...data, category: e.target.value })
                                    }}
                                />
                            </div>

                            <div>
                                <button
                                    onClick={() => {
                                        if (!submit) {
                                            handleSubmit()
                                        }
                                    }}
                                    class="hover:shadow-form w-full rounded-md bg-[rgba(0,0,0,.9)] py-3 px-8 text-center text-base font-semibold text-white outline-none">
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}