import React, { useState, createContext, useReducer } from "react";

export const context = createContext(null);

export const ContextProvider = ({ children }) => {
	// loading state
	const [isAnimating, setIsAnimating] = useState(false);

	// check if user profile is complete
	const [userProfileComplete, setUserProfileComplete] = useState(false);

	return (
		<context.Provider
			value={{
				// loading state
				isAnimating, setIsAnimating,

				// check if user profile is complete
				userProfileComplete, setUserProfileComplete
			}}
		>
			{children}
		</context.Provider>
	);
};