"use client";

import { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import { ExclamationCircleIcon, CheckBadgeIcon } from '@heroicons/react/20/solid';
import { context } from '../../context/Context';
import { AuthService } from '../../common/auth.service';
import { classNames } from '../../libraries/utility';
import { useNavigate } from 'react-router';

export default function VerifyOtp() {
	const [errorMsg, setErrorMsg] = useState(null);
	const [successMsg, setSuccessMsg] = useState(null);

	const { setIsAnimating } = useContext(context);

	const [timer, setTimer] = useState(45);
	const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
	const [otpCount, setOtpCount] = useState(1);

    const navigate = useNavigate();
	useEffect(() => {
		const uniqueId = sessionStorage.getItem('registererId');
		if (!uniqueId) {
			window.location.href = '/register'
		}
	}, [])
	useEffect(() => {
		const x = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
		return () => clearInterval(x);
	}, [timer]);


	// resend otp
	const resendOtp = async () => {
		try {
			const uniqueId = sessionStorage.getItem('registererId');
			if (!uniqueId) {
				window.location.href = '/register'
			}
			setResendButtonDisabled(true);
			setIsAnimating(true);
			const response = await AuthService.resendRegisterEmailVerificationOTP(uniqueId);
			setOtpCount(otpCount + 1);
			setTimer(45);
			setSuccessMsg(response);
			setErrorMsg(null);
		} catch (error) {
			setSuccessMsg(null);
			setErrorMsg(error);
		} finally {
			setResendButtonDisabled(false);
			setIsAnimating(false);
		}
	}

	return (
		<div className="min-h-full flex flex-col justify-center items-center py-40 sm:px-6 lg:px-8 bg-white px-4">
			<h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">Verification</h1>

			<div className="mt-8 w-full max-w-xl ">
				{
					successMsg &&
					<div className="rounded-md bg-green-50 p-4">
						<div className="flex">
							<div className="flex-shrink-0">
								<CheckBadgeIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="font-medium text-green-800">{successMsg}</h3>
							</div>
						</div>
					</div>
				}
				{
					errorMsg &&
					<div className="rounded-md bg-red-50 p-4">
						<div className="flex">
							<div className="flex-shrink-0">
								<ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="font-medium text-red-800">{errorMsg}</h3>
							</div>
						</div>
					</div>
				}

				<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 w-full">
					<Formik
						initialValues={{ otp: "" }}
						validate={(values) => {
							const errors = {};
							if (values.otp.trim() == '') {
								errors.otp = "Required";
							} else if (!/^[0-9]{6}$/.test(values.otp.trim())) {
								errors.otp = 'Otp must be of 6 digits length';
							}
							return errors;
						}}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								const uniqueId = sessionStorage.getItem('registererId');
								if (!uniqueId) {
									window.location.href = '/register'
								}
								setSubmitting(true);
								setIsAnimating(true);
								const response = await AuthService.verifyRegisterEmailVerificationOTP(uniqueId, values.otp);
								setErrorMsg(null);
                                window.location.reload();
							} catch (error) {
								setSuccessMsg(null);
								setErrorMsg(error);
							} finally {
								setSubmitting(false);
								setIsAnimating(false);
							}
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<form className="space-y-4" onSubmit={handleSubmit}>
								<div>
									<label htmlFor="email" className="block font-semibold text-gray-600">
										Enter Verification Otp sent on your email
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="otp"
											id="otp"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.otp}
											placeholder="Enter verification otp"
											className={classNames(
												errors.otp && touched.otp
													? "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-red-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
													: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-sm"
											)}
										/>
									</div>
									{
										errors.otp && touched.otp &&
										<p className="mt-2 text-red-600" id="otp-error">
											{errors.otp}
										</p>
									}
								</div>

								<div className="w-full flex justify-between items-center">

									<div className="mt-1 text-right w-full">
										{otpCount < 3 &&
											<>
												{timer > 0 ? (
													<span className="font-medium text-black focus:outline-none">
														You can request new OTP after {timer}s
													</span>
												) : (
													<button
														type="button"
														className="font-medium text-black focus:outline-none"
														onClick={() => resendOtp()}
														disabled={resendButtonDisabled}
													>
														Resend OTP
													</button>
												)}
											</>
										}
									</div>
								</div>

								<div>
									<button
										type="submit"
										disabled={isSubmitting}
										className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus-visible:outline-indigo-600"
									>
										Verify Otp
									</button>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}