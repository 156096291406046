import React from 'react'
// import { useContext } from 'react'
// import { context } from "../context/Context";
import { Navigate } from 'react-router-dom';
function ProfileRedirect() {
  // const { userProfileComplete  } = useContext(context);
  const userProfileComplete = JSON.parse(localStorage.getItem('currentUser')).profileComplete;
  return (
    <Navigate to={"/user/profile"} replace />

  )
}

export default ProfileRedirect