import { Outlet } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

function GuestLayout() {
	return (
		<div id="container" className="bg-white">
			<Header />

			<main >
			 	<Outlet/>
			</main>

			<Footer />
		</div>
	)
}

export default GuestLayout;