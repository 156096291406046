import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ChevronDownIcon,
  Cog6ToothIcon,
  UserCircleIcon,
  WrenchIcon,
  XMarkIcon,
  UserPlusIcon
} from "@heroicons/react/24/outline";
import { Fragment, useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import { classNames } from "../libraries/utility";
import { AuthService } from './../common/auth.service';
import { context } from "../context/Context";

const navigation = [
  // { name: "Dashboard", href: "/", icon: HomeIcon },
  { name: "Your Profile", href: "/user/profile", icon: UserCircleIcon },
  { name: "Upload Design", href: "/user/uploadDesign", icon: WrenchIcon },
  // { name: "Affiliate", href: "/user/affiliate", icon: UserPlusIcon }
  // { name: "FAQ", href: "/faqSection", icon: QuestionMarkCircleIcon }
];

const userNavigation = [
  { name: 'Settings', href: '/user/settings', icon: Cog6ToothIcon, }
];

export default function UserLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [current, setCurrent] = useState("Dashboard");
  const [currentUser, setCurrentUser] = useState(null);

  const { userProfileComplete, setUserProfileComplete } = useContext(context)
  useEffect(() => {
    if (AuthService.currentUserValue) {
      var _user = JSON.parse(AuthService.currentUserValue);
      setCurrentUser(_user);
      setUserProfileComplete(_user.userProfileComplete)
    }
  }, []);
  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src="/Qafto.png"
                      alt="Qafto"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {
                            <>
                              {navigation.map((item) => (
                                <li key={item.name}>
                                  <Link
                                    to={item.href}
                                    onClick={() => {
                                      setSidebarOpen(false)
                                      setCurrent(item.name)
                                    }}
                                    className={classNames(
                                      current == item.name
                                        ? "bg-gray-800 text-white"
                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                    {item.name}
                                  </Link>
                                </li>
                              ))}
                            </>
                          }
                        </ul>
                      </li>

                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img className="h-8 w-auto" src="/Qafto.png" alt="Qafto" />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {
                    navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          onClick={() => {
                            setSidebarOpen(false)
                            setCurrent(item.name)
                          }}
                          className={classNames(
                            current == item.name
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                          {item.name}
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </li>

            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-72">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}

          <div className="flex flex-1 justify-end gap-x-4 self-stretch lg:gap-x-6">

            <div className="flex items-center gap-x-4 lg:gap-x-6">
              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>

                  <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                    <span className="font-medium leading-none uppercase text-white">
                      {
                        currentUser?.name?.split(' ').length === 1 ? currentUser?.name?.split(' ')[0]?.[0] :
                          currentUser?.name?.split(' ')[0][0] + currentUser?.name?.split(' ')[currentUser?.name?.split(' ')?.length - 1]?.[0]
                      }
                      {/* {currentUser?.first_name[0] + currentUser?.last_name[0]} */}
                    </span>
                  </span>
                  <span className="hidden lg:flex lg:items-center">
                    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          onClick={() => { AuthService.logout(); }}
                          className={classNames(active ? 'bg-gray-50' : '', 'flex items-center px-3 py-1 text-sm leading-6 text-gray-800')}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-logout h-4 w-4 me-1" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                            <path d="M9 12h12l-3 -3" />
                            <path d="M18 15l3 -3" />
                          </svg>
                          Sign Out
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="bg-gray-100 min-h-[calc(100vh_-_4rem)]">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
