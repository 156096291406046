import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthService } from './common/auth.service';
import { ContextProvider } from './context/Context';

import './index.css';

import { context } from './context/Context';

// layouts
import UserLayout from './layouts/user';
import GuestLayout from './layouts/App';

import reportWebVitals from './reportWebVitals';
import TopLoadingBar from './components/TopLoadingBar';
import ProfileRedirect from './components/ProfileRedirect';
import UploadDesign from './screens/account/uploadDesign';
import VerifyOtp from './screens/guest/registerVerifyOtp';

// landing pages
const LandingPage = React.lazy(() => import('./screens/guest/landingPage'));

// guest
const Login = React.lazy(() => import('./screens/guest/login'));
const Register = React.lazy(() => import('./screens/guest/register'));
const ForgotPassword = React.lazy(() => import('./screens/guest/forgotPassword'));
const Verify2FACode = React.lazy(() => import('./screens/guest/verify2FACode'));
const VerifyToken = React.lazy(() => import('./screens/guest/verifyToken'));

const ResetPassword = React.lazy(() => import('./screens/guest/resetPassword'));

// user
const UserProfile = React.lazy(() => import('./screens/account/profile'));


function RequireAuth({ children }) {
  const { setUserProfileComplete, } = useContext(context);
  let location = useLocation();

  if (!AuthService.currentUserValue) {
    
    return <Navigate to="/guest/login" state={{ from: location }} replace />;
  } else {
    setUserProfileComplete(JSON.parse(localStorage.getItem('currentUser'))?.profileComplete)
  }

  return children;
}
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ContextProvider>
    <BrowserRouter>

      {/* toast notifications / alerts */}
      <ToastContainer />

      {/* progress bar */}
      <TopLoadingBar />


      {/* START - Auth Routes */}
      <Routes>
        <Route path="/" element={
          <GuestLayout />
        }>
          <Route index element={
            <React.Suspense fallback={<>---</>}>
              <LandingPage />
            </React.Suspense>
          } />
        </Route>

        <Route path="/guest/"
          element={
            AuthService.currentUserValue == null ?
              <GuestLayout />
              :
              <ProfileRedirect />
          }
        >
          <Route path="login" element={
            <React.Suspense fallback={<>...</>}>
              <Login />
            </React.Suspense>
          } />
          <Route path="register" element={
            <React.Suspense fallback={<>...</>}>
              <Register />
            </React.Suspense>
          } />
          <Route path="forgot-password" element={
            <React.Suspense fallback={<>...</>}>
              <ForgotPassword />
            </React.Suspense>
          } />
          <Route path="reset-password" element={
            <React.Suspense fallback={<>...</>}>
              <ResetPassword />
            </React.Suspense>
          } />
          <Route path="login-verify-otp" element={
            <React.Suspense fallback={<>...</>}>
              <Verify2FACode />
            </React.Suspense>
          } />
          <Route path="register-verify-otp" element={
            <React.Suspense fallback={<>...</>}>
              <VerifyOtp />
            </React.Suspense>
          } />
          <Route path="recover-account/:token" element={
            <React.Suspense fallback={<>...</>}>
              <VerifyToken />
            </React.Suspense>
          } />
        </Route>
        {/* END - Auth Routes */}

        {/* START - User Routes */}
        <Route path="/user/"
          element={
            <RequireAuth>
              <UserLayout />
            </RequireAuth>
          }
        >
          {/* {
            JSON.parse(AuthService.currentUserValue)?.profileComplete ? */}
          <Route path="profile" element={
            <React.Suspense fallback={<>...</>}>
              <UserProfile />
            </React.Suspense>
          } />
          {/* : */}
          <Route path="uploadDesign" element={
            <React.Suspense fallback={<>...</>}>
              <UploadDesign />
            </React.Suspense>
          } />
        </Route>
        {/* END - Auth Routes */}
      </Routes>
    </BrowserRouter>
  </ContextProvider>
);


reportWebVitals();
